<template>
  <v-dialog v-model="dialog" width="500" persistent>
    <v-card>
      <v-footer
        padless
        tile
        class="msaBlue white--text"
        style="position: sticky; top: 0; z-index: 999"
      >
        <v-container fluid>
          <v-row align="center">
            <v-col class="text-h6 pl-6 grow"> Change Orders </v-col>
          </v-row>
        </v-container>
      </v-footer>
      <v-card-text class="mt-4">
        <v-row>
          <v-col cols="12" align="right">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-blur
                  class="msaBlue white--text"
                  v-on="on"
                  @click="orderAlphanumerically()"
                >
                  <v-icon> mdi-order-alphabetical-ascending </v-icon>
                </v-btn>
              </template>
              <span>Order Alphabetically</span>
            </v-tooltip>
          </v-col>
          <v-col cols="12">
            <v-card>
              <v-list dense class="py-0">
                <v-list-item
                  :style="{ 'border-bottom': '1px solid gray' }"
                  class="lightBg"
                >
                  <v-list-item-content>
                    <v-list-item-title>Name</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <draggable :list="edited">
                  <v-list-item
                    v-for="item in edited"
                    :key="item.id"
                    :style="{ 'border-bottom': '1px solid gray' }"
                  >
                    <v-list-item-content>
                      <v-list-item-title :style="{ cursor: 'grab' }">
                        <v-icon class="mr-2" small> mdi-arrow-up-down</v-icon>
                        {{ item.name }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </draggable>
              </v-list>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
      <v-footer
        padless
        tile
        style="position: sticky; bottom: 0; z-index: 999"
        color="msaBlue"
      >
        <v-container fluid>
          <v-row>
            <v-col class="grow" align="right">
              <v-btn class="white red--text" @click="dialog = false"
                >cancel</v-btn
              >
            </v-col>
            <v-col class="shrink">
              <v-btn
                @click="updatePositions()"
                class="white msaBlue--text"
                :disabled="disableSave"
              >
                confirm
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-footer>
    </v-card>
  </v-dialog>
</template>

<script>
import Draggable from 'vuedraggable';

export default {
  name: 'ChangeOrdersDialog',
  components: {
    Draggable,
  },
  props: {
    items: {
      type: Array,
    },
    itemType: {
      type: String,
    },
  },
  computed: {
    disableSave() {
      return JSON.stringify(this.items) == JSON.stringify(this.edited);
    },
  },
  data() {
    return {
      edited: [],
      dialog: false,
    };
  },
  methods: {
    show() {
      this.$nextTick(() => {
        this.edited = JSON.parse(JSON.stringify(this.items));
        this.dialog = true;
      });
    },
    orderAlphanumerically() {
      const sortAlphaNum = (a, b) =>
        a.name.localeCompare(b.name, 'en', { numeric: true });
      this.edited.sort(sortAlphaNum);
    },
    updatePositions() {
      this.edited.forEach((item, index) => {
        item.position = index + 1;
      });
      this.save();
    },
    save() {
      const params = {
        loaderText: 'Loading...',
      };

      let url = '';

      if (this.itemType == 'groups') {
        params.groups = this.edited;
        url = 'update-private-document-group-positions?format=json';
      }

      if (this.itemType == 'documents') {
        params.documents = this.edited;
        url = 'update-private-document-positions?format=json';
      }

      this.$axios
        .post(url, params)
        .then(() => {
          this.$emit('reloadList');
          this.dialog = false;
        })
        .catch((error) => error);
    },
  },
};
</script>

<style></style>
