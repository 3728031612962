<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <template v-slot:activator="{ on: dialog, attrs }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn
            class="msaBlue white--text"
            v-bind="attrs"
            v-on="{ ...dialog, ...tooltip }"
            v-blur
            @click="getDataForUpload()"
          >
            <v-icon>mdi-cloud-upload-outline</v-icon>
          </v-btn>
        </template>
        <span>Upload</span>
      </v-tooltip>
    </template>
    <v-card tile>
      <v-footer
        padless
        tile
        class="msaBlue white--text"
        style="position: sticky; top: 0; z-index: 999"
      >
        <v-container fluid>
          <v-row align="center">
            <v-col class="text-h6 pl-6"> Upload Files </v-col>
            <v-col class="shrink pr-6">
              <v-btn color="white" icon @click="close()">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-footer>
      <v-card-text class="pt-4">
        <v-row align="center">
          <v-col cols="12">
            <v-file-input
              v-model="fileInput"
              multiple
              label="Choose Files"
              outlined
              dense
              accept=".pdf, .doc, .docx, .xlsx, .xls"
              hide-details
              prepend-icon="mdi-file-upload-outline"
              @change="onFileInput()"
            >
            </v-file-input>
          </v-col>
        </v-row>
        <v-row v-if="unacceptedFiles.length > 0">
          <v-col cols="12">
            <v-alert dense type="warning">
              Only PDF, Word and Excel files smaller than 100MB are allowed. The
              following file
              {{ unacceptedFiles.length > 1 ? 's' : '' }}
              will not be uploaded:
              <ul>
                <li v-for="file in unacceptedFiles" :key="file">
                  {{ file }}
                </li>
              </ul>
            </v-alert>
          </v-col>
        </v-row>
        <v-row v-if="documents.length > 0">
          <v-col cols="12">
            <PrivateDocumentLibraryUploadGlobalSettings
              :plainGroups="plainGroups"
              :hasEmployeeSelectedForAnyDocument="
                hasEmployeeSelectedForAnyDocument
              "
              @onGlobalDocumentGroupChange="onGlobalDocumentGroupChange($event)"
              @onGlobalExpiryDateChange="onGlobalExpiryDateChange($event)"
              @onGlobalLabelsChange="onGlobalLabelsChange($event)"
              @onGlobalEmployeesChange="onGlobalEmployeesChange($event)"
              :individualGroupChanged="individualGroupChanged"
              :individualExpiryDateChanged="individualExpiryDateChanged"
              :individualLabelsChanged="individualLabelsChanged"
              :individualEmployeesChanged="individualEmployeesChanged"
              :key="reload"
              :labels="labels"
              :employees="employees"
              @showGroupDetails="$emit('showGroupDetails', $event)"
              @folderChange="onFolderChange"
              @groupDelete="onGroupDelete"
            />
          </v-col>
          <v-col cols="12" v-for="document in documents" :key="document.id">
            <v-card>
              <v-card-title
                class="msaBlue--text text-body-1 font-weight-medium lightBg"
              >
                <v-row dense align="center">
                  <v-col>
                    {{ document.filename }}
                  </v-col>
                  <v-col class="shrink">
                    <v-btn
                      icon
                      color="red"
                      @click="removeDocument(document.id)"
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-title>
              <v-card-text class="pt-4">
                <v-row align="center">
                  <v-col>
                    <CustomizedAutoComplete
                      :value="document.group"
                      label="Select folder"
                      hide-details
                      :items="plainGroups"
                      prepend-icon="mdi-folder-file-outline"
                      :error="document.error"
                      @change="
                        individualGroupChanged = true;
                        document.group = $event;
                      "
                    />
                  </v-col>
                </v-row>
                <v-row align="center">
                  <v-col cols="12" md="6" class="pb-0">
                    <v-menu
                      v-model="document.menu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="document.expiryDate"
                          label="Pick an expiration date"
                          prepend-icon="mdi-calendar"
                          readonly
                          dense
                          outlined
                          clearable
                          v-bind="attrs"
                          v-on="on"
                          @click:clear="individualExpiryDateChanged = true"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="document.expiryDate"
                        :min="minDate"
                        scrollable
                        no-title
                        @change="individualExpiryDateChanged = true"
                        @input="document.menu = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row align="center">
                  <v-col cols="12" md="6">
                    <CustomizedAutoComplete
                      deletable-chips
                      :value="document.attachedLabels"
                      :items="labels"
                      hide-details
                      label="Attach groups"
                      multiple
                      prepend-icon="mdi-tag-multiple-outline"
                      clearable
                      @change="
                        individualLabelsChanged = true;
                        document.attachedLabels = $event;
                      "
                    />
                  </v-col>
                  <v-col cols="12" md="6">
                    <CustomizedAutoComplete
                      deletable-chips
                      :value="document.assignedEmployees"
                      :items="employees"
                      item-text="fullname"
                      clearable
                      hide-details
                      label="Pick users to assign"
                      multiple
                      prepend-icon="mdi-account-multiple"
                      @change="
                        individualEmployeesChanged = true;
                        document.assignedEmployees = $event;
                      "
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row style="height: 40px"> </v-row>
      </v-card-text>
      <v-card-actions
        style="position: fixed; bottom: 0; width: 100%"
        class="msaBlue"
      >
        <v-spacer> </v-spacer>
        <v-btn
          v-if="documents.length > 0"
          class="red white--text"
          @click="close()"
        >
          cancel
        </v-btn>

        <v-btn color="white" @click="submit()" :disabled="disableSave">
          save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import PrivateDocumentLibraryUploadGlobalSettings from '@/components/PrivateDocumentLibrary/PrivateDocumentLibraryUploadGlobalSettings.vue';

export default {
  name: 'PrivateDocumentLibraryUpload',
  components: {
    PrivateDocumentLibraryUploadGlobalSettings,
  },
  props: {
    groups: {
      type: Array,
    },
    plainGroups: {
      type: Array,
    },
  },
  data() {
    return {
      dialog: false,
      fileInput: [],
      documents: [],
      globalDocumentGroup: null,
      globalExpiryDate: null,
      selectedGlobalLabels: [],
      selectedGlobalEmployees: [],
      employees: [],
      labels: [],
      minDate: `${this.$constants.DATE_SELECTOR_RANGE.MIN}`,
      individualGroupChanged: false,
      individualExpiryDateChanged: false,
      individualLabelsChanged: false,
      individualEmployeesChanged: false,
      reload: false,
      acceptedMimeTypes: [
        'application/pdf',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.ms-excel',
      ],
      unacceptedFiles: [],
      globalSendEmail: true,
      loaded: false,
    };
  },
  computed: {
    allLabelsSelected() {
      return (document) => document.attachedLabels.length == this.labels.length;
    },
    labelSelected() {
      return (document) =>
        document.attachedLabels.length > 0 && !this.allLabelsSelected(document);
    },
    allEmployeesSelected() {
      return (document) =>
        document.assignedEmployees.length == this.employees.length;
    },
    employeeSelected() {
      return (document) =>
        document.assignedEmployees.length > 0 &&
        !this.allEmployeesSelected(document);
    },
    disableSave() {
      return (
        this.documents.length == 0 ||
        this.documents.some((document) => document.group == null)
      );
    },
    hasEmployeeSelectedForAnyDocument() {
      return this.documents.some(
        (document) => document.assignedEmployees.length > 0,
      );
    },
    showSendEmail() {
      return (document) => document.assignedEmployees.length > 0;
    },
  },
  methods: {
    onGroupDelete(id) {
      this.updateDocumentOnGroupDelete(id);
      this.$emit('deleteGroup', id);
    },
    updateDocumentOnGroupDelete(groupId) {
      this.documents.map((doc) => {
        if (doc.group == groupId) {
          doc.group = null;
        }
      });
    },
    onFolderChange() {
      this.$emit('folderChange');
    },
    async onFileInput() {
      this.unacceptedFiles = [];
      for (let i = 0; i < this.fileInput.length; i++) {
        const file = this.fileInput[i];
        try {
          await this.$helpers.processFile(file, this.acceptedMimeTypes, true);
        } catch (error) {
          if (error.message) {
            this.unacceptedFiles.push(file.name);
            continue;
          }
        }

        const filenameInfo = this.$helpers.splitFilenameByExtension(file.name);
        const nameWithoutExtension = filenameInfo.name;
        this.documents.push({
          name: nameWithoutExtension,
          filename: file.name,
          type: file.type,
          file: file,
          attachedLabels: this.selectedGlobalLabels,
          assignedEmployees: this.selectedGlobalEmployees,
          group: this.globalDocumentGroup,
          id: this.$helpers.createGuid(),
          expiryDate: this.globalExpiryDate,
          menu: false,
          error: false,
        });
      }
      this.fileInput = [];
    },
    onGlobalDocumentGroupChange(globalGroup) {
      this.individualGroupChanged = false;
      this.globalDocumentGroup = globalGroup;
      this.documents.forEach((document) => {
        document.group = globalGroup;
      });
    },
    onGlobalExpiryDateChange(globalExpiryDate) {
      this.individualExpiryDateChanged = false;
      this.globalExpiryDate = globalExpiryDate;
      this.documents.forEach((document) => {
        document.expiryDate = globalExpiryDate;
      });
    },
    onGlobalLabelsChange(globalLabels) {
      this.individualLabelsChanged = false;
      this.selectedGlobalLabels = globalLabels.slice();
      this.documents.forEach((document) => {
        document.attachedLabels = globalLabels.slice();
      });
    },
    onGlobalEmployeesChange(globalEmployees) {
      this.individualEmployeesChanged = false;
      this.selectedGlobalEmployees = globalEmployees.slice();
      this.documents.forEach((document) => {
        document.assignedEmployees = globalEmployees.slice();
      });
    },
    onGlobalSendEmailChange(val) {
      this.globalSendEmail = val;
    },
    clearAllDocuments() {
      this.documents = [];
      this.unacceptedFiles = [];

      this.globalDocumentGroup = null;
      this.globalExpiryDate = null;
      this.selectedGlobalLabels = [];
      this.selectedGlobalEmployees = [];

      this.individualGroupChanged = false;
      this.individualExpiryDateChanged = false;
      this.individualLabelsChanged = false;
      this.individualEmployeesChanged = false;

      this.reload = !this.reload;
    },
    close(autoClose) {
      const confirm = () => {
        this.clearAllDocuments();
        this.dialog = false;
        this.$emit('close');
      };
      if (!autoClose && this.documents.length) {
        this.$root.showMessage(
          'Cancel upload',
          'Are you sure you want to cancel? This will result in the file(s) not being uploaded.',
          () => confirm(),
          () => false,
          'Confirm',
          'Cancel',
        );
      } else {
        confirm();
      }
    },
    checkGroupSelection() {
      let result = true;
      this.documents.forEach((document) => {
        if (document.group == null) {
          document.error = true;
          result = false;
        } else {
          document.error = false;
        }
      });
      return result;
    },
    async submit() {
      if (!this.checkGroupSelection()) {
        return;
      }
      const documents = [];
      const failed = [];
      for (let i = 0; i < this.documents.length; i++) {
        const result = await this.uploadFile(this.documents[i], i);
        if (!result) {
          failed.push(this.documents[i].filename);
          continue;
        }
        // remove file blob to reduce payload size
        delete this.documents[i].file;

        let expiryDate = this.documents[i].expiryDate ?? '';
        if (expiryDate != '') {
          expiryDate = this.$helpers.formatDateTimeToUTC(expiryDate);
        }
        const guid = result;
        documents.push({ ...this.documents[i], expiryDate, guid });
      }
      let warningMessage = '';
      if (failed.length) {
        warningMessage =
          'the following files are not saved due to internal error. <br>' +
          failed.join('<br>');
      }

      //upload warning response due to fail to convert empty spreadsheet to PDF
      const uploadWarning = await this.updateToDB(documents);
      if (uploadWarning.length) {
        warningMessage +=
          '<br>Some of the changes for the following files are not saved due to failed to convert to PDF. <br>' +
          uploadWarning.join('<br>');
      }

      if (warningMessage) {
        this.$root.showMessage(
          'Warning',
          warningMessage,
          () => {},
          null,
          'Ok',
          '',
        );
      }
      this.$emit('saved');
      this.close(true);
    },
    async updateToDB(documents) {
      const params = {
        sendEmail: this.globalSendEmail,
        documents,
        loaderText: 'Saving...',
      };

      const url = 'create-private-library-documents?format=json';

      return await this.$axios
        .post(url, params)
        .then((response) => response.data)
        .catch(() => []);
    },
    async uploadFile(payload, index) {
      const url = 'upload-file-for-private-document-library?format=json';
      const headers = {
        'Content-Type': 'multipart/form-data',
      };
      const params = new FormData();
      params.append('file', payload.file);
      params.append('type', payload.type);
      params.append('name', payload.filename);
      params.append('disableAutoError', true);
      params.append(
        'loaderText',
        `Uploading file (${index + 1}/${this.documents.length})...`,
      );
      params.append('jwtToken', this.$store.getters.jwtToken);

      return await this.$axios
        .post(url, params, headers)
        .then((response) => response.data)
        .catch(() => false);
    },
    removeDocument(docId) {
      this.documents = this.documents.filter(
        (document) => document.id != docId,
      );
    },
    getDataForUpload() {
      if (!this.loaded) {
        const params = {
          loaderText: 'Loading...',
        };
        const url = 'get-data-to-manage-private-documents?format=json';

        this.$axios
          .post(url, params)
          .then((response) => {
            this.labels = response.data.labels;
            this.employees = response.data.employees;
            this.loaded = true;
          })
          .catch((error) => error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.v-select::v-deep .v-chip {
  /* chip background color msaBlue */
  background-color: #3564ac;

  /* chip text color */
  color: white;

  /* chip X color */
  button {
    color: white;
  }
}
</style>
