<template>
  <v-container fluid :class="selected.length > 0 ? 'pb-16' : ''">
    <v-row>
      <v-col cols="6">
        <SearchBar
          @search="onSearch"
          searchLabel="Search by file name..."
          ref="searchBar"
        />
      </v-col>
      <v-spacer></v-spacer>
      <v-col
        class="grow"
        align="right"
        v-if="$route.name == 'CompanyDocumentLibrary'"
      >
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              v-blur
              class="msaBlue white--text"
              v-on="on"
              @click="showDefaultGroupDetails"
            >
              <v-icon> mdi-folder-plus-outline </v-icon>
            </v-btn>
          </template>

          <span> Add Folder</span>
        </v-tooltip>
      </v-col>
      <v-col class="shrink">
        <PrivateDocumentLibraryFilter
          ref="filters"
          :groups="plainGroups"
          @applyFilters="onApplyFilters"
        />
      </v-col>
      <v-col class="shrink" v-if="$route.name == 'CompanyDocumentLibrary'">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              v-blur
              class="msaBlue white--text"
              v-on="on"
              :disabled="!canChangeOrders"
              @click="orderFolders(groups)"
            >
              <v-icon> mdi-folder-arrow-up-down-outline </v-icon>
            </v-btn>
          </template>
          <span>Order Folders</span>
        </v-tooltip>
      </v-col>
      <v-col class="shrink" v-if="$route.name == 'CompanyDocumentLibrary'">
        <PrivateDocumentLibraryUpload
          :key="reloadUploadModal"
          :plainGroups="plainGroups"
          @saved="onUploadSaved"
          @showGroupDetails="showGroupDetails($event)"
          @deleteGroup="onGroupDeleted"
          @folderChange="onFolderChange"
        />
      </v-col>
      <v-col class="shrink" v-if="$route.name == 'CompanyDocumentLibrary'">
        <PrivateDocumentLibraryExport :filters="filters" />
      </v-col>
    </v-row>
    <v-row>
      <v-col v-if="groups.length">
        <PrivateDocumentGroups
          ref="groups"
          :key="reload"
          :groups="groups"
          :canChangeOrders="canChangeOrders"
          :selected="selected"
          @showGroupDetails="showGroupDetails($event)"
          @deleteGroup="deleteGroup($event)"
          @showDocumentDetails="showDocumentDetails($event)"
          @reloadList="getDocumentsInGroups()"
          @showAssignments="showAssignments($event)"
          @showLabels="showLabels($event)"
          @orderFolders="orderFolders($event)"
          @orderDocuments="orderDocuments($event)"
          @selected="onSelect($event)"
        />
      </v-col>
      <v-col class="text-center" v-if="!canChangeOrders && groups.length == 0">
        No results found
      </v-col>
    </v-row>

    <PrivateDocumentMassManage
      :documents="selected"
      :groups="plainGroups"
      v-if="selected.length > 0"
      @onUnselect="onSelect({ docs: [$event], value: false })"
      @unselectAll="onUnselectAll"
      @confirmed="massManaged()"
    />

    <PrivateDocumentGroupDetail
      ref="groupDetail"
      :group="selectedGroup"
      :key="reloadGroupDetails"
      @confirmed="onGroupSaved()"
      @closed="reloadGroupDetails = $helpers.createGuid()"
    />

    <PrivateDocumentDetails
      ref="documentDetail"
      :groups="plainGroups"
      :documentId="selectedDocument.id"
      @reloadList="getDocumentsInGroups()"
    />

    <PrivateDocumentManageAssignments
      ref="assignments"
      :document="selectedDocument"
      @reloadList="getDocumentsInGroups()"
    />

    <PrivateDocumentManageLabels
      ref="labels"
      :documentId="selectedDocument.id"
      @reloadList="
        // this will make sure the selected documents props get updated
        getDocumentsInGroups().then(() => $refs.groups.updateSelection())
      "
    />

    <ChangeOrdersDialog
      ref="changeOrders"
      :items="selectedItems"
      :itemType="selectedItemsType"
      @reloadList="onGroupPositionsUpdated()"
    />
  </v-container>
</template>
<script>
import ChangeOrdersDialog from '@/components/PrivateDocumentLibrary/ChangeOrdersDialog.vue';
import PrivateDocumentDetails from '@/components/PrivateDocumentLibrary/PrivateDocumentDetails.vue';
import PrivateDocumentGroupDetail from '@/components/PrivateDocumentLibrary/PrivateDocumentGroupDetail.vue';
import PrivateDocumentGroups from '@/components/PrivateDocumentLibrary/PrivateDocumentGroups.vue';
import PrivateDocumentLibraryExport from '@/components/PrivateDocumentLibrary/PrivateDocumentLibraryExport.vue';
import PrivateDocumentLibraryFilter from '@/components/Filters/PrivateDocumentLibraryFilter.vue';
import PrivateDocumentLibraryUpload from '@/components/PrivateDocumentLibrary/PrivateDocumentLibraryUpload.vue';
import PrivateDocumentManageAssignments from '@/components/PrivateDocumentLibrary/PrivateDocumentManageAssignments.vue';
import PrivateDocumentManageLabels from '@/components/PrivateDocumentLibrary/PrivateDocumentManageLabels.vue';
import PrivateDocumentMassManage from '@/components/PrivateDocumentLibrary/PrivateDocumentMassManage.vue';
import SearchBar from '@/components/SearchBar.vue';
import { phantomAccessCheck } from '../global/functions.js';

export default {
  name: 'DocumentLibraryPage',
  components: {
    ChangeOrdersDialog,
    PrivateDocumentDetails,
    PrivateDocumentGroupDetail,
    PrivateDocumentGroups,
    PrivateDocumentLibraryExport,
    PrivateDocumentLibraryFilter,
    PrivateDocumentLibraryUpload,
    PrivateDocumentManageAssignments,
    PrivateDocumentManageLabels,
    PrivateDocumentMassManage,
    SearchBar,
  },
  computed: {
    canChangeOrders() {
      return (
        JSON.stringify(this.$constants.PRIVATE_DOCUMENT_LIBRARY_FILTERS) ==
        JSON.stringify(this.filters)
      );
    },
  },
  data() {
    return {
      groups: [],
      plainGroups: [],
      reload: this.$helpers.createGuid(),
      reloadGroupDetails: this.$helpers.createGuid(),
      reloadUploadModal: this.$helpers.createGuid(),
      selected: [],
      selectedDocument: {},
      selectedGroup: { name: '', id: 0 },
      selectedItems: [],
      selectedItemsType: '',
      filters: JSON.parse(
        JSON.stringify(this.$constants.PRIVATE_DOCUMENT_LIBRARY_FILTERS),
      ),
    };
  },
  methods: {
    onFolderChange() {
      this.getPlainGroups();
    },
    onUploadSaved() {
      this.filters = JSON.parse(
        JSON.stringify(this.$constants.PRIVATE_DOCUMENT_LIBRARY_FILTERS),
      );
      this.$refs.searchBar.setSearch('');
      this.$refs.filters.setFilters(this.filters);
      this.getDocumentsInGroups();
      this.reloadUploadModal = this.$helpers.createGuid();
    },
    onApplyFilters(filters) {
      if (filters.isClearEvent && this.$refs.groups) {
        this.$refs.groups.resetPanels();
        delete filters.isClearEvent;
      }
      this.filters = JSON.parse(JSON.stringify(filters));
      this.$refs.searchBar.setSearch(this.filters.search);
      this.getDocumentsInGroups().then(
        () => (this.reload = this.$helpers.createGuid()),
      );
    },
    onUnselectAll() {
      this.selected = [];
    },
    onSelect(event) {
      if (event.value) {
        event.docs.forEach((d) => {
          const index = this.selected.findIndex((s) => s.id == d.id);
          if (index == -1) {
            this.selected.push(d);
          } else {
            if (JSON.stringify(d) != JSON.stringify(this.selected[index])) {
              this.selected.splice(index, 1, d);
            }
          }
        });
      } else {
        this.selected = this.selected.filter(
          (selected) => !event.docs.some((doc) => doc.id == selected.id),
        );
      }
    },
    getDocumentsInGroups(useLoader) {
      const params = {
        loaderText: useLoader || 'Loading...',
        filters: this.filters,
        isFilterOn: this.canChangeOrders ? 0 : 1,
      };

      const url =
        this.$route.name == 'CompanyDocumentLibrary'
          ? 'get-documents-in-groups-by-company?format=json'
          : 'get-documents-in-groups-by-user?format=json';
      return this.$axios.post(url, params).then((response) => {
        this.groups = response.data;
      });
    },
    onSearch(keyword) {
      this.filters.search = keyword;
      this.$refs.filters.setFilters(this.filters);
      this.getDocumentsInGroups().then(
        () => (this.reload = this.$helpers.createGuid()),
      );
    },
    showDefaultGroupDetails() {
      const defaultGroupInfo = {
        id: 0,
        level: 1,
        name: '',
        parentPrivateLibraryGroupId: 0,
      };
      this.showGroupDetails(defaultGroupInfo);
    },
    showGroupDetails(event) {
      this.selectedGroup = event;
      this.$refs.groupDetail.show();
    },
    onGroupDeleted() {
      this.getPlainGroups();
      this.getDocumentsInGroups(false);
    },
    deleteGroup(id) {
      const params = {
        loaderText: 'Loading...',
        id,
      };
      const url = 'delete-private-document-group?format=json';
      this.$axios
        .post(url, params)
        .then(() => {
          this.getPlainGroups();
          this.getDocumentsInGroups();
        })
        .catch((error) => error);
    },
    showDocumentDetails(documentId) {
      this.selectedDocument = {
        id: documentId,
      };
      this.$refs.documentDetail.show();
    },
    showAssignments(document) {
      this.selectedDocument = document;
      this.$refs.assignments.show();
    },
    showLabels(documentId) {
      this.selectedDocument = {
        id: documentId,
      };
      this.$refs.labels.show();
    },
    orderFolders(groups) {
      this.selectedItems = groups.map((g) => {
        return { id: g.id, name: g.name };
      });
      this.selectedItemsType = 'groups';
      this.$refs.changeOrders.show();
    },
    orderDocuments(documents) {
      this.selectedItems = documents.map((d) => {
        return {
          id: d.id,
          name: d.name,
          privateLibraryGroupId: d.privateLibraryGroupId,
        };
      });
      this.selectedItemsType = 'documents';
      this.$refs.changeOrders.show();
    },
    massManaged() {
      this.selected = [];
      this.getDocumentsInGroups();
    },
    getPlainGroups() {
      const params = {};

      if (this.$route.name == 'CompanyDocumentLibrary') {
        params.userType = this.$constants.USER_TYPES.WEB_ADMIN;
      } else {
        params.userType = this.$constants.USER_TYPES.NORMAL_USER;
      }

      this.$axios
        .post('get-private-library-groups-for-select?format=json', params)
        .then((response) => {
          this.plainGroups = response.data;
        });
    },
    onGroupPositionsUpdated() {
      this.getPlainGroups();
      this.getDocumentsInGroups();
    },
    onGroupSaved() {
      this.getPlainGroups();
      this.getDocumentsInGroups();
    },
  },
  mounted() {
    if (
      this.$route.name == 'UserDocumentLibrary' &&
      !phantomAccessCheck(this, 'Library')
    ) {
      return;
    }
    this.getPlainGroups();
    this.getDocumentsInGroups();
  },
};
</script>
<style></style>
